<template>
  <section class="mt-4">
    <b-tabs>
      <b-tab-item label="Таблица">
        <b-pagination
          :total="total"
          :current="currentPage"
          :simple="false"
          :per-page="filters.limit"
          :paginated="false"
          :pagination-simple="true"
          order="is-right"
          @change="pageChange">
        </b-pagination>
        <div class="table-container">
          <b-table
            :data="data"
            :bordered="true"
            :narrowed="true"
            :hoverable="true"
            detailed
            default-sort-direction="desc"
            default-sort="date">
            <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
              <router-link :to="{name: 'a-order', params: {id: props.row.id}}">
                {{ props.row.id }}
              </router-link>
            </b-table-column>
            <b-table-column field="RK7_VisitID" label="VisitID" width="40" numeric centered v-slot="props">
              {{ props.row.RK7_VisitID }}
            </b-table-column>
            <b-table-column field="RK7_orderName" label="OrderName" width="40" numeric centered v-slot="props">
              {{ props.row.RK7_orderName }}
            </b-table-column>
            <b-table-column field="date" label="Дата заказа" centered v-slot="props">
              {{ props.row.date }}
            </b-table-column>
            <b-table-column field="customer_id" label="Клиент" width="40" numeric centered v-slot="props">
              <router-link :to="{name: 'a-client', params: {id: props.row.customer_id}}">
                {{ props.row.customer.username }}
              </router-link>
            </b-table-column>
            <b-table-column field="count" label="Количество блюд" centered v-slot="props">
              {{ props.row.count }}
            </b-table-column>
            <b-table-column field="address" label="Адрес" centered v-slot="props">
              <router-link :to="{name: 'a-address', params: {id: props.row.address_id }}" class="navbar-item">
                {{ props.row.address }}
              </router-link>
            </b-table-column>
            <b-table-column field="order_type" label="Тип заказа" centered v-slot="props">
              <template v-if="props.row.order_type == 0">
                <b-tooltip label="Самовывоз">
                  <font-awesome-icon icon="portrait" />
                </b-tooltip>
              </template>
              <template v-else="">
                <b-tooltip label="Доставка">
                  <font-awesome-icon icon="shipping-fast" />
                </b-tooltip>
              </template>
            </b-table-column>
            <b-table-column field="order_type" label="Тип оплаты" centered v-slot="props">
              <template v-if="props.row.pay_online_type == 1">
                <b-tooltip label="Онлайн">
                  <font-awesome-icon icon="globe" />
                </b-tooltip>
              </template>
              <template v-else="">
                <template v-if="props.row.pay_type == 0">
                  <b-tooltip label="Наличные">
                    <font-awesome-icon icon="wallet" />
                  </b-tooltip>
                </template>
                <template v-else="">
                  <b-tooltip label="Карта">
                    <font-awesome-icon icon="credit-card" />
                  </b-tooltip>
                </template>
              </template>
            </b-table-column>
            <b-table-column field="comment" label="Коментарий" centered v-slot="props">
              {{ props.row.comment }}
            </b-table-column>
            <b-table-column field="isPayment" label="Статус оплаты" centered v-slot="props">
              <template v-if="props.row.isPayment == null">
                <b-tooltip label="Платеж не создался">
                  ???
                </b-tooltip>
              </template>
              <template v-else="">
                <template v-if="props.row.isPayment == true">
                  <b-tooltip label="Платеж одобрен">
                    +
                  </b-tooltip>
                </template>
                <template v-else="">
                  <b-tooltip label="Платеж не одобрен">
                    -
                  </b-tooltip>
                </template>
              </template>
            </b-table-column>
            <b-table-column field="count_sync" label="Отправки" centered v-slot="props">
              {{ props.row.count_sync }}
            </b-table-column>
            <b-table-column field="sync" label="Заказ отправлен" centered v-slot="props">
              <template v-if="props.row.is_sync == false">
                <b-tooltip label="Нет">
                  <font-awesome-icon icon="times" />
                </b-tooltip>
              </template>
              <template v-else="">
                <b-tooltip label="Да">
                  <font-awesome-icon icon="sync" />
                </b-tooltip>
              </template>
            </b-table-column>
            <b-table-column field="action" label="Действие" centered v-slot="props">
              <b-tooltip label="Просмотр">
                <router-link :to="{name: 'a-order', params: {id: props.row.id }}" class="navbar-item">
                  <font-awesome-icon icon="eye" />
                </router-link>
              </b-tooltip>
            </b-table-column>
            <template slot="detail" slot-scope="props">
              <b-table
                :data="props.row.order"
                :bordered="true"
                :narrowed="true"
                :hoverable="true"
                default-sort-direction="desc"
                default-sort="date">
                <b-table-column field="id" label="ID" width="40" numeric centered v-slot="props">
                  {{ props.row.dish.id }}
                </b-table-column>
                <b-table-column field="code" label="CODE" width="40" numeric centered v-slot="props">
                  {{ props.row.dish.code }}
                </b-table-column>
                <b-table-column field="name" label="Наименование" width="40" numeric centered v-slot="props">
                  {{ props.row.dish.name }}
                </b-table-column>
                <b-table-column field="price" label="Цена" width="40" numeric centered v-slot="props">
                  {{ props.row.dish.price/100 }}
                </b-table-column>
                <b-table-column field="modifiers" label="Модификаторы" width="40" numeric centered v-slot="props">
                  <!-- {{ props.row.modifiers.length }} -->
                  <b-taglist v-for="modifier in props.row.modifiers" v-bind:key="modifier.id">
                    <b-tag type="is-info" >{{modifier.name}}</b-tag>
                  </b-taglist>
                </b-table-column>
                <b-table-column field="modifiers" label="Модификаторы комбо" width="40" numeric centered v-slot="props">
                  <!-- {{ props.row.combos.length }} -->
                  <b-taglist v-for="combo in props.row.combos" v-bind:key="combo.id">
                    <b-tag type="is-info" >{{combo.name}}</b-tag>
                  </b-taglist>
                </b-table-column>
              </b-table>
            </template>
          </b-table>
        </div>
      </b-tab-item>
      <b-tab-item label="JSON">
        <div class="app-json">
          <vue-json-pretty
            :path="'res'"
            :data="data">
          </vue-json-pretty>
        </div>
      </b-tab-item>
    </b-tabs>
  </section>
</template>


<script>
import { EventBus, limits } from '@/utils'
import { getOrders, getPageOrders } from '@/api/order'
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  name: "OrdersTable",
  components: {
    VueJsonPretty
  },
  data() {
    const data = []

    return {
      data: data,
      limits: limits,
      currentPage: 1,
      total: 0,
      filters: {
        order: 'asc',
        limit: 25
      },
      is_admin: false
    }
  },
  computed: {
    //
  },
  mounted: function() {
    let $this = this

    getOrders(this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;
        $this.total = data.total

        data.data.forEach((item) => {
          this.data.push(item)
        })
      })
      .catch(
        this.data = []
      )

    // EventBus.$on('error', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.error')} ${error}`,
    //     message: 'error',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

    // EventBus.$on('fail', (error) => {
    //   $this.pageChange()
    //   this.$toast.open({
    //     // message: `${this.$i18n.t('form.fail')} ${error}`,
    //     message: 'fail',
    //     type: 'is-danger',
    //     position: 'is-bottom'
    //   })
    // })

  },
  beforeDestroy: function(){
    EventBus.$off('error')
    EventBus.$off('fail')
  },
  methods: {
    pageChange(page){
      if(page){
        this.currentPage = page
      }
      getPageOrders(this.currentPage, this.filters, this.$store.state.jwt_user)
      .then(response => {
        let data = response.data;
        
        this.data = []
        this.total = data.total
        data.data.forEach((item) => {
          this.data.push(item)
        })

      })
      .catch(
        this.data = []
      )
    },
  }
}
</script>
<style>
</style>